import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const MinecraftersCookbook = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me (and my small sous chef) make recipes from The Minecrafter's Cookbook by Tara Theoharis, with over 40 Minecraft-themed recipes for any player who wants to bring the game to life.";

  return (
    <Layout>
      <Seo title="The Minecrafter's Cookbook" image={image} meta={metaData} description={description}/>
      <h1>The Minecrafter's Cookbook</h1>
      <h2>More Than 40 Game-Themed Dinners, Desserts, Snacks, and Drinks to Craft Together</h2>
      <p>
        This is a cookbook aimed at children, though the average child may need adult assistance for
        some of the recipes. My son will be helping me with these videos, so you can see how a novice
        cook (who loves Minecraft!) handles the recipes.
      </p>
      <p>
        Included are recipes for foods you can find in the game, and others that are inspired by
        creatures in the game. From drinks and snacks to entrees and desserts, everyone can find
        something fun to make!
      </p>
      <p>
        <a href="http://www.taratheoharis.com/">Tara Theoharis</a> is also the author of two other
        cookbooks besides this one, but both are themed. If you're a Broadway enthusiast or a Tomb Raider
        player, you might want to check out her other books.
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/minecrafter's cookbook/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default MinecraftersCookbook;
